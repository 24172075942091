

import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Speciality from '../components/Specialty/Specialty'
import HomeBg from '../images/hpHomeBG.jpg'
import muhd from '../images/muhammadpassport.jpg'
import habeeb from '../images/habeeb2.jpg'
import uremu from '../images/uremu.jpg'
import ejiro from '../images/ejiro.jpg'

const About = () => (
  <Layout>

    <div class="inner-page">
  <div class="slider-item homBg " style={{backgroundImage:`url(${HomeBg})`}} >
    <div class="container">
      <div class="flex flex-row slider-text items-center justify-center" style={{height:450}}>
        {/* <div class="flex-col items-center justify-center text-center col-sm-12 element-animate"> */}
          <h1 class="self-center text-white text-5xl">ABOUT US</h1>
        {/* </div> */}
      </div>
    </div>
  </div>
</div>
<div class="flex-row about-mobile px-0 mx-2 md:px-8 md:mx-8">
  <div class="flex-col mx-auto pt-4 ">
    <div>
      <p class="flex" >
        <span class="Ha text-6xl">H</span><span class="pt-4" style={{lineHeight: 2}}>ealthpeer NG was born out of a personal experience. We saw a huge gap in communication between
          medical consultants and their patients. Aside being noscomophobic, visiting hospitals can sometimes
          be a hassle in this part of the world. Long queues and waiting time, irregular meeting with consultants,
          false and late diagnosis are all common issues faced while seeking medical help.</span>
          {/* <!-- Healthpeer NG is a health-tech startup in Abuja,  --> */}
      </p>
    </div>
    
    <p style={{lineHeight: 2}}>
      Healthpeer NG is a health-tech startup in Abuja, Nigeria founded in 2018 with a mission to drastically give more
      people access to medical
      advices as well as allowing consultants to earn extra income whilst keeping tabs on their patient on the go 24/7.
    </p>
    <br />
    {/* <ul>
        <strong>Our consultants are highly verified and our range of services include:</strong>
      <li><i> Medical Advices/Diagnosis </i></li>
      <li><i>Therapy sessions including Depression/ Anger Management</i></li>
      <li><i>Mental Health checkup sessions</i></li>
      <li><i>Bloodbank Donation and payment</i></li>
      <li><i>Family planning</i></li>
      <li><i>Counseling and lots more</i></li>
    </ul> */}
    
  </div>
</div>
<Speciality />

<div class="section">
  <div class="container">
    {/* <div class="row justify-content-center mb-5 element-animate">
      <div class="col-md-8 text-center">
        <h2 class="mb-4 section-title">Healthpeer is a <strong>Product</strong> of &nbsp;
          <a href="www.cubicle-ng.com"><img src="assets/image/cubicle.png" width="170" height="70" style="padding-bottom: 10px;"  alt="Cubicle NG" class="img-fluid" /></a>
        </h2>
        <!-- <p class="mb-5 lead">Healthpeer NG is a product of &nbsp;
          <a href="www.cubicle-ng.com" ><img src="assets/image/cubicle.png" width="150" height="40"  alt="Cubicle NG" class="img-fluid"></a>
        </p> -->
      </div>
    </div> */}
    <span class="text-black flex font-medium text-4xl justify-center pt-4">
                MEET OUR &nbsp;<span class="font-extra-bold underline"> TEAM</span>
    </span>
    <div class="grid grid-cols-1 md:grid-cols-3 md:gap-8 mx-4">
      <div class="flex-1 flex-col">
        <div class="media  d-block media-custom text-center" >
          <a href="#" class="flex justify-center"><img src={muhd} style={{bordeRadius: '50%', height: 100, width: 100}} width="70" height="70" alt="Image Placeholder" class="p-2 img-fluid" /></a>
          <div class="media-body">
            <h3 class="mt-0 text-indigo-900">Muhammad Olawale Shuaib</h3>
            <h3 class="mt-0 text-indigo-900">CEO</h3>
            <p class="float-left text-justify ">A MSc. graduate of information technology and 
              a certified ethical hacker. A young dynamic entrepreneur, a fullstack developer and passionate visionary of intellingent systems. 
              </p>
          </div>
        </div>
      </div>
      <div class="flex-1 flex-col">
        <div class="media d-block media-custom text-center">
          <a href="#" class="flex justify-center"><img src={habeeb} width="70" height="70" style={{bordeRadius: '50%', height: 100, width: 100}} alt="Image Placeholder" class="p-2 img-fluid" /></a>
          <div class="media-body">
            <h3 class="mt-0 text-indigo-900">Habeeb Sunmonu</h3>
            <h3 class="mt-0 text-indigo-900">CTO</h3>
            <p class="float-left text-justify ">A graduate of physics and a software developer with a passion for developing innovative systems.</p>
          </div>
        </div>
      </div>
      <div class="flex-1 flex-col">
        <div class="media d-block media-custom text-center">
          <a href="#" class="flex justify-center"><img src={uremu} width="70" height="70" style={{bordeRadius: '50%', height: 100, width: 100}} alt="Image Placeholder" class="p-2 img-fluid" /></a>
          <div class="media-body">
            <h3 class="mt-0 text-indigo-900">Uruemu Majoroh</h3>
            <h3 class="mt-0 text-indigo-900">Project Manager</h3>
            <p class="float-left text-justify ">A programme and project management Specialist with over 12 years’ experience. His vision is to bring about a desirous society where all citizens can have a voice and contribute effectively in their leadership circle.
                </p>
          </div>
        </div>
      </div>
      <div class="flex-1 flex-col">
        <div class="media d-block media-custom text-center">
          <a href="#" class="flex justify-center"><img src={ejiro} width="70" height="70" style={{bordeRadius: '50%', height: 100, width: 100}} alt="Image Placeholder" class="p-2 img-fluid" /></a>
          <div class="media-body">
            <h3 class="mt-0 text-indigo-900">Ejiro Eugene Amrakavbo</h3>
            <h3 class="mt-0 text-indigo-900">Marketing Partner </h3>
            <p class="float-left text-justify ">A business administrator with a knack for people and customer success. He is an expert on media adverts and PR.</p>
          </div>
        </div>
      </div>
    </div> 

  </div>
</div>
    
  </Layout>
)

export default About



